import React from "react";
import { connect } from "react-redux";
import PaginatedList from "../components/PaginatedList";
import { ContentHeader } from "../components/shared";
import { formatDate } from "../utilities/format-date";
import { fetchCurrentOrganization } from "../actions/current-organization";
import { fetchOrganizations } from "../actions/organizations";
import { createImpersonation } from "../actions/impersonations";
import { withRouter } from "react-router";

class Organizations extends React.Component {
  componentWillMount() {
    this.props.fetchOrganizations();
  }

  organization = o => {
    if (o.organizationId === this.props.currentUser.organizationId) {
      return <tr key={o.organizationId} />;
    }
    return (
      <tr className="table__row" key={o.organizationId}>
        <td
          className="table__data"
          onClick={() => this.createImpersonation(o.organizationId)}
        >
          <a
            className="table__link"
          >
            {o.facebookPageName}
          </a>
        </td>
        <td
          className="table__data"
          onClick={() => this.createImpersonation(o.organizationId)}
        >
          <a className="table__link">
            {o.label}
          </a>
        </td>
        <td
          className="table__data"
          onClick={() => this.createImpersonation(o.organizationId)}
        >
          <a
            className="table__link"
            style={{
              textTransform: o.shopifyStorefrontSlug && 'capitalize'
            }}
          >
            {o.shopifyStorefrontSlug?.replace(/-/g, ' ')}
          </a>
        </td>
        <td className="table__data">{o.organizationId}</td>
        <td className="table__data">{formatDate(o.createdAt)}</td>
        <td className="table__data">
          <a
            className="table__link"
            onClick={() => this.createImpersonation(o.organizationId)}
          >
            Impersonate
          </a>
        </td>
      </tr>
    );
  };

  render = () => {
    return (
      <div>
        <ContentHeader left={<h1 className="page-title">Organizations</h1>} />
        <div className="card list">
          <PaginatedList
            items={this.props.organizations}
            component={this.organization}
            headers={["Organization", "Label", "Shopify Storefront Name", "ID", "Date Added", ""]}
          />
        </div>
      </div>
    );
  };

  createImpersonation = async organizationId => {
    await this.props.createImpersonation(organizationId);
    await this.props.fetchCurrentOrganization();
    this.props.history.push("/");
  };
}

const mapStateToProps = state => {
  return {
    organizations: state.organizations.list,
    currentUser: state.currentUser.currentUser || {},
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    {
      createImpersonation,
      fetchCurrentOrganization,
      fetchOrganizations,
    }
  )(Organizations)
);
